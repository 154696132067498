import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";

import { Text } from "src/components/Text";
import { breakpoint } from "src/utils/media-query";
import { getImageMetadata, IGatsbyImage } from "src/utils/image";

import { PageContent } from "./layout/PageContent";

interface ArchiveArticleProps {
  image?: IGatsbyImage;
  caption?: string;
  text?: string;
}

export const ArchiveArticle: React.FC<ArchiveArticleProps> = ({
  image,
  caption,
  text
}) => {
  const [ articleImage, articleImageAlt ] = getImageMetadata(image);

  return (
    <Wrapper>
      <PageContent>
        {articleImage && (
          <GatsbyImage image={articleImage} alt={articleImageAlt} />
        )}
        {caption && (
          <CaptionText as="label" firstLineCaps>
            {caption}
          </CaptionText>
        )}
        <FlexWrapper>
          {text?.split(/\n+/g)?.map((paragraph, i) => (
            <Paragraph
              as="p"
              firstLineCaps={i === 0}
              key={`${i + 1}`}
            >
              {paragraph}
            </Paragraph>
          ))}
        </FlexWrapper>
      </PageContent>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;

  .gatsby-image-wrapper {
    width: auto!important;

    ${breakpoint("tabletLL")} {
      align-self: center;
      width: 100%!important;
      max-height: 480px;
      margin-top: 3rem;
    }
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 1rem;
  margin-top: 3rem;

  ${breakpoint("tabletLL")} {
    margin-left: 0;
    margin-right: 0;
  }
`;

const CaptionText = styled(Text)`
  margin: 1rem 0;
  text-align: center;
`;

const Paragraph = styled(Text)`
  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`;