import React from "react";
import { graphql, PageProps } from "gatsby";
import styled from "styled-components";

import { PageLayout } from "src/components/layout/PageLayout";
import { ArchiveArticle } from "src/components/ArchiveArticle";
import { Carousel } from "src/components/Carousel";
import { Text } from "src/components/Text";
import { breakpoint } from "src/utils/media-query";
import { useWindowSize } from "src/hooks/useWindowSize";

import "pure-react-carousel/dist/react-carousel.es.css";

interface ArchivePageProps extends PageProps {
  data: GatsbyTypes.ArchivePageQuery;
}

const ArchivePage: React.FC<ArchivePageProps> = ({ data }) => {
  const archiveArticlesInfo = data.strapiArchive?.archive_article || [];
  const { width } = useWindowSize();

  const ArchiveArticles = archiveArticlesInfo.map((article, i) => (
    <ArchiveArticle
      key={article?.id || i}
      image={article?.archive_image}
      caption={article?.archive_image_caption}
      text={article?.archive_text}
    />
  ));

  return (
    <PageLayout title="Archive" width="extra-wide">
      <TitleWrapper>
        <Text as="h1" uppercase>
          Archive
        </Text>
      </TitleWrapper>
      <CarouselWrapper>
        <Carousel
          slides={ArchiveArticles}
          fullScreen={width > 768}
          intrinsicHeight
          topControls
        />
      </CarouselWrapper>
    </PageLayout>
  );
};

export default ArchivePage;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;

  ${breakpoint("tabletLL")} {
    padding-top: 3rem;
  }
`;

const CarouselWrapper = styled.div`
  .carousel__slider {
    max-width: 100%;
  }
`;

export const query = graphql`
  query ArchivePage {
    strapiArchive {
      archive_article {
        id
        archive_image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        archive_image_caption
        archive_text
      }
    }
  }
`;